import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as CompanyService from '../../services/companyService';
import { TableOptionsState } from 'src/utils/useTablePagination';

const SLICE_NAME = 'company';

const initialState = {
  templateDocuments: {
    data: [],
    totalCount: 0
  },
  companyDocuments: [],
  pending: false,
  companyInternalDocuments: {
    data: [],
    totalCount: 0
  }
};

export const editCompanyDocument = createAsyncThunk(
  'admin/edit-company-document/put',
  async ({ data, id }: { data: any; id: number }) => {
    const res = await CompanyService.editCompanyDocument(data, id);
    return res;
  }
);

export const deleteCompanyDocument = createAsyncThunk(
  'admin/delete-company-document/delete',
  async (id: number) => {
    await CompanyService.deleteCompanyDocument(id);
  }
);

export const getCompanyTemplateDocuments = createAsyncThunk(
  'admin/list-document-templates/get',
  async (data: { options: TableOptionsState; folder: number }) => {
    const res = await CompanyService.getCompanyTemplateDocuments(data);
    return res;
  }
);

export const getCompanyApiKey = createAsyncThunk(
  'companies/get-api-key',
  async () => CompanyService.getApiKey()
);

export const enableCompanyApiKey = createAsyncThunk(
  'companies/enable-api-key',
  async (data: { active: number }) => CompanyService.enableApiKey(data)
);

export const getAllIndustries = createAsyncThunk(
  'common/industry/get',
  async () => {
    const res = await CompanyService.getAllIndustries();
    return res;
  }
);

export const getAllLicenses = createAsyncThunk(
  'common/license/get',
  async () => {
    const res = await CompanyService.getAllLicenses();
    return res;
  }
);

export const getAllSingleLicenses = createAsyncThunk(
  'common/license-single-user/get',
  async () => {
    const res = await CompanyService.getAllSingleLicenses();
    return res;
  }
);

export const signUpCompany = createAsyncThunk(
  'registration-company/post',
  async (data: any) => {
    const res = await CompanyService.signUpCompany(data);
    return res;
  }
);

export const signUpSingle = createAsyncThunk(
  'registration-single-user/post',
  async (data: any) => {
    const res = await CompanyService.signUpSingle(data);
    return res;
  }
);

export const renewCompanyApiKey = createAsyncThunk(
  'company/api-key/renew/get',
  async () => CompanyService.renewCompanyApiKey()
);

export const getCompanyInternalDocuments = createAsyncThunk(
  'admin/list-company-internal-templates/get',
  async (data: { options: TableOptionsState }) => {
    const res = await CompanyService.getCompanyInternalDocuments(data);
    return res;
  }
);

export const addCompanyInternalDocuments = createAsyncThunk(
  'admin/add-company-internal-document/post',
  async (data: any) => {
    const res = await CompanyService.addCompanyInternalDocuments(data);
    return res;
  }
);

const pendingMethod = (state) => {
  state.pending = true;
};

const companySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [getAllIndustries.pending.toString()]: pendingMethod,
    [getAllLicenses.pending.toString()]: pendingMethod,
    [getAllSingleLicenses.pending.toString()]: pendingMethod,
    [signUpCompany.pending.toString()]: pendingMethod,
    [signUpSingle.pending.toString()]: pendingMethod,
    [getCompanyTemplateDocuments.pending.toString()]: pendingMethod,
    [getCompanyInternalDocuments.pending.toString()]: pendingMethod,
    [addCompanyInternalDocuments.pending.toString()]: pendingMethod,
    [deleteCompanyDocument.pending.toString()]: pendingMethod,
    [editCompanyDocument.pending.toString()]: pendingMethod,

    [deleteCompanyDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteCompanyDocument.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [editCompanyDocument.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [editCompanyDocument.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [addCompanyInternalDocuments.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [addCompanyInternalDocuments.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getCompanyInternalDocuments.fulfilled.toString()]: (state, action) => {
      state.companyInternalDocuments = action.payload;
      state.pending = false;
    },
    [getCompanyInternalDocuments.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllIndustries.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllIndustries.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getCompanyTemplateDocuments.fulfilled.toString()]: (state, action) => {
      state.templateDocuments = action.payload;
      state.pending = false;
    },
    [getCompanyTemplateDocuments.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllLicenses.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllLicenses.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllSingleLicenses.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllSingleLicenses.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [signUpCompany.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [signUpCompany.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [signUpSingle.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [signUpSingle.rejected.toString()]: (state, action) => {
      state.pending = false;
    }
  }
});

const { reducer } = companySlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const companySelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  companyDocuments: (rootState) => {
    const appState = getAppState(rootState);
    return appState.companyDocuments;
  },
  templateDocuments: (rootState) => {
    const appState = getAppState(rootState);
    return appState.templateDocuments;
  },
  companyInternalDocuments: (rootState) => {
    const appState = getAppState(rootState);
    return appState.companyInternalDocuments;
  }
};
