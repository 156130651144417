import React, { useEffect, useState } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import styles from '../AddCompany/styles.module.css';
import { useFormikContext } from 'formik';
import { RegisterCompanyType } from '.';
import { useAppDispatch } from 'src/redux';
import { getAllIndustries } from 'src/redux/slices/companySlice';

const CompanyForm: React.FC = () => {

  const [industries, setIndustries] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllIndustries()).then((data) => {
      setIndustries(data.payload);
    });
  }, []);

  const formik = useFormikContext<RegisterCompanyType>();

  return (
    <Grid container>
      <Grid item className={styles.loginItem}>
        <TextField
          name="pib"
          error={formik.errors.pib != null}
          label="PIB"
          placeholder="Unesite PIB nove kompanije"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.pib}
          fullWidth
        />
        {formik.errors.pib ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.pib}</i>
          </Typography>
        ) : (
          formik.errors.pib && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.pib}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="companyName"
          error={formik.errors.companyName != null}
          label="Naziv"
          placeholder="Unesite naziv"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyName}
        />{' '}
        {formik.errors.companyName ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.companyName}</i>
          </Typography>
        ) : (
          formik.errors.companyName && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.companyName}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="organizationType"
          error={formik.errors.organizationType != null}
          label="Tip kompanije"
          placeholder="Unesite tip kompanije"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.organizationType}
        />
        {formik.errors.organizationType ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.organizationType}</i>
          </Typography>
        ) : (
          formik.errors.organizationType && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.organizationType}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          select
          name="industryId"
          label="Delatnost"
          error={formik.errors.industryId != null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.industryId}
          fullWidth
        >
          {industries.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.val}
              </MenuItem>
            );
          })}
        </TextField>
        {formik.errors.industryId ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.industryId}</i>
          </Typography>
        ) : (
          formik.errors.industryId && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.industryId}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="companyAddress"
          error={formik.errors.companyAddress != null}
          label="Adresa"
          placeholder="Unesite adresu kompanije"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyAddress}
        />
        {formik.errors.companyAddress ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.companyAddress}</i>
          </Typography>
        ) : (
          formik.errors.companyAddress && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.companyAddress}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="companyHeadquarters"
          error={formik.errors.companyHeadquarters != null}
          label="Sedište kompanije"
          placeholder="Unesite sedište kompanije"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyHeadquarters}
          fullWidth
        />
        {formik.errors.companyHeadquarters ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.companyHeadquarters}</i>
          </Typography>
        ) : (
          formik.errors.companyHeadquarters && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.companyHeadquarters}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          error={formik.errors.companyPhone != null}
          name="companyPhone"
          label="Broj telefona"
          placeholder="Unesite broj telefona kompanije"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyPhone}
          fullWidth
        />
        {formik.errors.companyPhone ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.companyPhone}</i>
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default CompanyForm;
