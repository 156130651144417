import { ReactNode } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

export interface MenuAdminItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuAdminItem[];
  name: string;
  acc?: string;
}

export interface MenuItems {
  items: MenuAdminItem[];
}

const adminMenuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Kompanije',
        link: '/admin/companies',
        icon: ApartmentIcon,
        acc: 'admin'
      },
      {
        name: 'Korisnici',
        link: '/admin/users',
        icon: PeopleIcon,
        acc: 'admin'
      },
      {
        name: 'Fizička lica',
        link: '/admin/single-users',
        icon: PeopleOutlineIcon,
        acc: 'admin'
      },
      {
        name: 'Administratori',
        link: '/admin/admins',
        icon: PeopleIcon,
        acc: 'admin'
      },
      {
        name: 'Templejti Dokumenata',
        link: '/admin/documents',
        icon: FileDownloadIcon,
        acc: 'admin'
      }
    ]
  }
];
export const userMenuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Pretraga',
        icon: CalendarTodayIcon,
        acc: 'search',
        items: [
          {
            name: 'Nova pretraga',
            link: '/search',
            acc: 'search'
          },
          {
            name: 'Istorija',
            link: '/history/list',
            acc: 'search'
          }
        ]
      },
      {
        name: 'Klijenti',
        link: '/clients',
        icon: PeopleIcon,
        acc: 'clients'
      },
      {
        name: 'Korisnici',
        link: '/users',
        icon: PeopleIcon,
        acc: 'company_users'
      },
      {
        name: 'Interna dokumenta',
        link: '/internal-documents',
        icon: InsertDriveFileIcon,
        acc: 'download'
      },
      {
        name: 'Templejti dokumenata',
        link: '/download',
        icon: FileDownloadIcon,
        acc: 'download'
      }
    ]
  }
];

export default adminMenuItems;
