import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ChangePasswordType } from 'src/models/changePassword';
import { User } from 'src/models/user';
import * as UserService from '../../services/userService';

const SLICE_NAME = 'user';

const initialState = {
  allUsers: {
    data: [] as User[],
    totalCount: 0
  },
  pending: false
};

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (id: number) => {
    const res = await UserService.deleteUser(id);
    return res;
  }
);

export const register = createAsyncThunk('users/post', async (data: User) => {
  const res = await UserService.register(data);
  return res;
});
export const changePassword = createAsyncThunk(
  'users/changePass',
  async (data: ChangePasswordType) => {
    const res = await UserService.changePassword(data);
    return res;
  }
);
export const editUser = createAsyncThunk('users/patch', async (data: User) => {
  const res = await UserService.editUser(data);
  return res;
});
export const getAllUsers = createAsyncThunk(
  'users/get',
  async (data: {
    page: number;
    pageSize: number;
    sortBy: string;
    search: string;
    admins?: boolean;
  }) => {
    console.log('admins', data.admins);
    const res = await UserService.allUsers(
      data.page,
      data.pageSize,
      data.sortBy,
      data.search,
      data.admins
    );
    return res;
  }
);
export const lockUnlockUser = createAsyncThunk(
  'users/lock',
  async (data: any) => {
    const body = {
      id: data.id,
      role: data.role,
      type: data.role,
      firstName: data.firstName,
      lastName: data.lastName,
      newUser: false,
      locked: !data.locked
    };
    const res = await UserService.editUser(body);
    return res;
  }
);
const pendingMethod = (state) => {
  state.pending = true;
};

const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [register.pending.toString()]: pendingMethod,
    [editUser.pending.toString()]: pendingMethod,
    [editUser.pending.toString()]: (state, action) => {
      state.pending = false;
    },
    [editUser.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [register.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [register.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [deleteUser.pending.toString()]: pendingMethod,
    [deleteUser.fulfilled.toString()]: (state, action) => {
      state.pending = false;
    },
    [getAllUsers.pending.toString()]: pendingMethod,
    [getAllUsers.fulfilled.toString()]: (state, action) => {
      state.allUsers = action.payload;

      state.pending = false;
    },
    [lockUnlockUser.pending.toString()]: pendingMethod,
    [lockUnlockUser.pending.toString()]: (state, action) => {
      state.pending = false;
    },
    [lockUnlockUser.rejected.toString()]: (state, action) => {
      state.pending = false;
    },
    [changePassword.pending.toString()]: pendingMethod,
    [changePassword.pending.toString()]: (state, action) => {
      state.pending = false;
    },
    [changePassword.rejected.toString()]: (state, action) => {
      state.pending = false;
    }
  }
});

const { reducer } = userSlice;
export default reducer;

const getAppState = (state) => state[SLICE_NAME];

export const userSelector = {
  pending: (rootState) => {
    const appState = getAppState(rootState);
    return appState.pending;
  },
  allUsers: (rootState) => {
    const appState = getAppState(rootState);
    return appState.allUsers;
  }
};
