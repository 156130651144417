import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import UserListPage from '../content/pages/UserList';
import PrivateRoute from './PrivateRoute';
import { NonAuthRoute } from './NonAuthRoute';
import { accList } from '../models/accList';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications
const LoginPage = Loader(lazy(() => import('src/content/pages/login')));

const RegisterPage = Loader(
  lazy(() => import('src/content/pages/SelectUserType'))
);

const RegisterCompanyPage = Loader(
  lazy(() => import('src/content/pages/RegisterCompany'))
);

const RegisterSinglePage = Loader(
  lazy(() => import('src/content/pages/RegisterSingle'))
);

const CompanyListPage = Loader(
  lazy(() => import('src/content/pages/CompanyList'))
);
const NewSearchPage = Loader(
  lazy(() => import('src/content/modules/Search/pages/NewSearch'))
);

const SearchResultsPage = Loader(
  lazy(() => import('src/content/modules/Search/pages/SearchResults'))
);

const SearchHistoryPage = Loader(
  lazy(() => import('src/content/modules/Search/pages/SearchHistoryTable'))
);
const DocumentsPage = Loader(
  lazy(() => import('src/content/pages/DocumentsList'))
);
const AdminDocumentsPage = Loader(
  lazy(() => import('src/content/pages/AdminDocuments'))
);
const SingleCompanyListPage = Loader(
  lazy(() => import('src/content/pages/SingleCompanyList'))
);
const ClientListPage = Loader(
  lazy(() => import('src/content/modules/Clients/pages/ClientList'))
);
const AddEditClientPersonPage = Loader(
  lazy(() => import('src/content/modules/Clients/pages/AddEditClientPerson'))
);
const AddEditClientCompanyPage = Loader(
  lazy(() => import('src/content/modules/Clients/pages/AddEditClientCompany'))
);
const CompanyDocumentsPage = Loader(
  lazy(() => import('src/content/pages/CompanyDocuments'))
);
// Status

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const Success = Loader(lazy(() => import('src/content/pages/SuccessPage')));

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: 'login',
        element: (
          <NonAuthRoute>
            <LoginPage />
          </NonAuthRoute>
        )
      },
      {
        path: 'register',
        element: (
          <NonAuthRoute>
            <RegisterPage />
          </NonAuthRoute>
        )
      },
      {
        path: 'register-company',
        element: (
          <NonAuthRoute>
            <RegisterCompanyPage />
          </NonAuthRoute>
        )
      },
      {
        path: 'register-person',
        element: (
          <NonAuthRoute>
            <RegisterSinglePage />
          </NonAuthRoute>
        )
      },
      {
        path: 'success',
        element: (
          <NonAuthRoute>
            <Success />
          </NonAuthRoute>
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/',
    element: (
      <PrivateRoute acc={accList}>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'search',
        element: (
          <PrivateRoute acc={['search']}>
            <NewSearchPage />
          </PrivateRoute>
        )
      },
      {
        path: 'history/:searchHistoryId',
        element: (
          <PrivateRoute acc={['search']}>
            <SearchResultsPage />
          </PrivateRoute>
        )
      },
      {
        path: 'users',
        element: (
          <PrivateRoute acc={['company_users']}>
            <UserListPage />
          </PrivateRoute>
        )
      },
      {
        path: 'clients/',
        element: (
          <PrivateRoute acc={['clients']}>
            <ClientListPage />
          </PrivateRoute>
        )
      },
      {
        path: 'clients/add-person',
        element: (
          <PrivateRoute acc={['clients']}>
            <AddEditClientPersonPage />
          </PrivateRoute>
        )
      },
      {
        path: 'clients/add-company',
        element: (
          <PrivateRoute acc={['clients']}>
            <AddEditClientCompanyPage />
          </PrivateRoute>
        )
      },
      {
        path: 'clients/company/:clientId',
        element: (
          <PrivateRoute acc={['clients']}>
            <AddEditClientCompanyPage />
          </PrivateRoute>
        )
      },
      {
        path: 'clients/person/:clientId',
        element: (
          <PrivateRoute acc={['clients']}>
            <AddEditClientPersonPage />
          </PrivateRoute>
        )
      },
      {
        path: '/history/list',
        element: (
          <PrivateRoute acc={['search']}>
            <SearchHistoryPage />
          </PrivateRoute>
        )
      },
      {
        path: '/download',
        element: (
          <PrivateRoute acc={['download']}>
            <DocumentsPage />
          </PrivateRoute>
        )
      },
      {
        path: '/internal-documents',
        element: (
          <PrivateRoute acc={['download']}>
            <CompanyDocumentsPage />
          </PrivateRoute>
        )
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <PrivateRoute acc={['admin']}>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/admin/companies" replace />
      },
      {
        path: 'users',
        element: <UserListPage />
      },
      {
        path: 'single-users',
        element: <SingleCompanyListPage />
      },
      {
        path: 'admins',
        element: <UserListPage admins />
      },
      {
        path: 'companies',
        element: <CompanyListPage />
      },
      {
        path: 'documents',
        element: <AdminDocumentsPage />
      }
    ]
  }
];

export default routes;
