import {
  Grid,
  TextField,
  Typography,
  Paper,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import styles from './styles.module.css';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { editUser, register, userSelector } from 'src/redux/slices/userSlice';
import { useAppDispatch } from 'src/redux';
import {
  getAllCompanies,
  adminSelector
} from 'src/redux/slices/adminSlice';
import { useSelector } from 'react-redux';
import { Company } from 'src/models/company';
import { LoadingButton } from '@mui/lab';
import { Role } from 'src/models/role';
import { getAccList } from 'src/services/authService';

const paperStyle = {
  padding: 10,
  width: 542,
  margin: '10px auto',
  maxHeight: 650,
  overflow: 'auto'
};
const btnstyle = { width: '100%' };

const SIZE_MESSAGE = '*Polje sadrži više od maksimalnog broja karaktera';
const REQUIRED_FIELD = '*Obavezno polje';

let message;
const createValidate = (admins: boolean) => (values) => {
  const errors: {
    password?: string;
    email?: string;
    emailValidate?: string;
    confirmPassword?: string;
    confirmValidate?: string;
    passwordLength?: string;
    firstName?: string;
    firstNameValidate?: string;
    lastNameValidate?: string;
    lastName?: string;
    companyId?: string;
  } = {};
  if (!values.password) {
    errors.password = REQUIRED_FIELD;
  } else if (values.password.length < 8) {
    errors.passwordLength = '*Lozinka mora da sadrži minimum 8 znakova';
  }
  if (!values.firstName) {
    errors.firstName = REQUIRED_FIELD;
  } else if (values.firstName.length > 50) {
    errors.firstNameValidate = SIZE_MESSAGE;
  }
  if (!values.lastName) {
    errors.lastName = REQUIRED_FIELD;
  } else if (values.lastName.length > 50) {
    errors.lastNameValidate = SIZE_MESSAGE;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = REQUIRED_FIELD;
  } else if (values.confirmPassword !== values.password) {
    errors.confirmValidate = '*Lozinke moraju biti iste';
  }
  if (!values.email) {
    errors.email = REQUIRED_FIELD;
  } else if (values.email.length < 5) {
    errors.emailValidate = '*Polje sadrži manje od minimalnog broja karaktera';
  } else if (values.email.length > 250) {
    errors.emailValidate = SIZE_MESSAGE;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.emailValidate = '*Nepostojeća email adresa';
  }
  if (!values.companyId && admins) {
    errors.companyId = REQUIRED_FIELD;
  }
  return errors;
};
const editValidate = (admins: boolean) => (values) => {
  const errors: {
    firstName?: string;
    lastName?: string;
    companyId?: string;
    firstNameValidate?: string;
    lastNameValidate?: string;
  } = {};
  if (!values.firstName) {
    errors.firstName = REQUIRED_FIELD;
  } else if (values.firstName.length > 50) {
    errors.firstNameValidate = SIZE_MESSAGE;
  }
  if (!values.lastName) {
    errors.lastName = REQUIRED_FIELD;
  } else if (values.lastName.length > 50) {
    errors.lastNameValidate = SIZE_MESSAGE;
  }
  if (!values.companyId && admins) {
    errors.companyId = REQUIRED_FIELD;
  }
  return errors;
};
const AddUserComponent = (props: any) => {
  const initialValue = {
    companyId: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    role: props.admins ? Role.ADMIN : Role.COMPANY_ADMIN,
    type: props.admins ? Role.ADMIN : Role.COMPANY_ADMIN,
  };

  const pending = useSelector(userSelector.pending);
  const acc = getAccList();
  const isAdmin = acc.includes('admin');
  const dispatch = useAppDispatch();
  const allCompanies = useSelector(adminSelector.allCompanies);
  const formik = useFormik({
    initialValues: props.editedUser ?? initialValue,
    enableReinitialize: true,
    validate: props.editedUser
      ? editValidate(props.admins)
      : createValidate(props.admins),
    onSubmit: async (values) => {
      let response;
      delete values.confirmPassword;
      if (props.editedUser) {
        delete values.companyName;
        response = await dispatch(editUser(
          {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            type: values.type,
            locked: values.locked
          } as any
        ));
      } else {
        response = await dispatch(register(
         isAdmin ? {
            email: values.email,
            password: values.password,
            companyId: values.companyId,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            type: values.type,
          } as any : 
          {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            type: values.type,
          } as any
        ));
      }
      if (response.meta.requestStatus === 'fulfilled') {
        props.handleCloseModule();
      }
      if (response.meta.requestStatus === 'rejected') {
        if (response.error.name === `Error 409`) {
          message = 'Korisnik sa ovom email adresom već postoji';
        } else {
          message = response.error.message || 'Došlo je do nepoznate greške na serveru';
        }
      }
    }
  });

  useEffect(() => {
    if (isAdmin)
      dispatch(getAllCompanies({ page: 0, pageSize: 1000, sortBy: 'companyName', industryId: '0', licenseId: '0' }));
    formik.resetForm();
    message = '';
  }, [props.isOpen]);
  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={props.handleCloseModule}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={10} style={paperStyle}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item className={styles.loginItem}>
                <h2>{props.editedUser ? 'Izmenite' : 'Unesite'} korisnika</h2>
                <Typography>
                  Molimo vas da popunite polja kako bi
                  {props.editedUser ? ' ažurirali' : ' uneli novog'} korisnika.
                </Typography>
              </Grid>
              <Grid item className={styles.loginItem}>
                <TextField
                  error={formik.errors.emailValidate != null}
                  name="email"
                  label="Korisnički e-mail"
                  placeholder="Unesi korisnički e-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  fullWidth
                  disabled={props.editedUser !== null}
                />
                {formik.errors.email ? (
                  <Typography
                    color="text.secondary"
                    className={styles.textForm}
                  >
                    <i>{formik.errors.email}</i>
                  </Typography>
                ) : (
                  formik.errors.emailValidate && (
                    <Typography color="error" className={styles.textForm}>
                      <i>{formik.errors.emailValidate}</i>
                    </Typography>
                  )
                )}
              </Grid>
              {props.editedUser === null && (
                <>
                  <Grid item className={styles.loginItem}>
                    <TextField
                      error={formik.errors.passwordLength != null}
                      name="password"
                      label="Lozinka"
                      placeholder="Unesi lozinku"
                      type="password"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />{' '}
                    {formik.errors.password ? (
                      <Typography
                        color="text.secondary"
                        className={styles.textForm}
                      >
                        <i>{formik.errors.password}</i>
                      </Typography>
                    ) : (
                      formik.errors.passwordLength && (
                        <Typography color="error" className={styles.textForm}>
                          <i>{formik.errors.passwordLength}</i>
                        </Typography>
                      )
                    )}
                  </Grid>
                  <Grid item className={styles.loginItem}>
                    <TextField
                      error={formik.errors.confirmValidate != null}
                      name="confirmPassword"
                      label="Potvrda lozinke"
                      placeholder="Unesi ponovo lozinku"
                      type="password"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />{' '}
                    {formik.errors.confirmPassword ? (
                      <Typography
                        color="text.secondary"
                        className={styles.textForm}
                      >
                        <i>{formik.errors.confirmPassword}</i>
                      </Typography>
                    ) : (
                      formik.errors.confirmValidate && (
                        <Typography color="error" className={styles.textForm}>
                          <i>{formik.errors.confirmValidate}</i>
                        </Typography>
                      )
                    )}
                  </Grid>
                </>
              )}
              <Grid item className={styles.loginItem}>
                <TextField
                  name="firstName"
                  label="Ime"
                  placeholder="Unesi ime"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={formik.errors.firstNameValidate != null}
                />
                {formik.errors.firstName ? (
                  <Typography
                    color="text.secondary"
                    className={styles.textForm}
                  >
                    <i>{formik.errors.firstName}</i>
                  </Typography>
                ) : (
                  formik.errors.firstNameValidate && (
                    <Typography color="error" className={styles.textForm}>
                      <i>{formik.errors.firstNameValidate}</i>
                    </Typography>
                  )
                )}
              </Grid>
              <Grid item className={styles.loginItem}>
                <TextField
                  name="lastName"
                  label="Prezime"
                  placeholder="Unesi prezime"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={formik.errors.lastNameValidate != null}
                  fullWidth
                />
                {formik.errors.lastName ? (
                  <Typography
                    color="text.secondary"
                    className={styles.textForm}
                  >
                    <i>{formik.errors.lastName}</i>
                  </Typography>
                ) : (
                  formik.errors.lastNameValidate && (
                    <Typography color="error" className={styles.textForm}>
                      <i>{formik.errors.lastNameValidate}</i>
                    </Typography>
                  )
                )}
              </Grid>
              {!props.admins && (
                <Grid className={styles.loginItem}>
                  <FormControl fullWidth>
                    <InputLabel id="role-select-label">Rola</InputLabel>
                    <Select
                      labelId="role-select-label"
                      id="role-select"
                      value={formik.values.type}
                      name="type"
                      label="Rola"
                      onChange={(event) => {
                        formik.setFieldValue('type', event.target.value);
                        formik.setFieldValue('role', event.target.value);
                      }}
                    >
                      <MenuItem value={Role.COMPANY_ADMIN}>Admin</MenuItem>
                      <MenuItem value={Role.COMPANY_USER}>Korisnik</MenuItem>
                      <MenuItem value={Role.COMPANY_OWNER}>Ovlašćeno lice</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isAdmin && !props.editedUser && (
                <Grid item className={styles.loginItem}>
                  <Autocomplete
                    options={allCompanies.data}
                    disableClearable
                    defaultValue={
                      props.editedUser?.companyId
                        ? allCompanies.data.find(
                          (company) =>
                            company.id === props.editedUser.companyId
                        )
                        : null
                    }
                    getOptionLabel={(option: Company) => option.companyName}
                    onBlur={formik.handleBlur}
                    onChange={(e, value: Company) => {
                      formik.setFieldValue(
                        'companyId',
                        value !== null ? value.id : formik.initialValues
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Izaberi kompaniju"
                        label="Kompanija"
                        fullWidth
                        name="companyId"
                        {...params}
                      />
                    )}
                  />
                  {formik.errors.companyId ? (
                    <Typography
                      color="text.secondary"
                      className={styles.textForm}
                    >
                      <i>{formik.errors.companyId}</i>
                    </Typography>
                  ) : null}
                </Grid>
              )}
              {message && (
                <Grid item className={styles.globalErrorMesage}>
                  <Typography
                    color="error"
                    className={styles.globalErrorMesage}
                  >
                    <i>{message}</i>
                  </Typography>
                </Grid>
              )}
              <Grid item className={styles.loginItem} style={btnstyle}>
                <LoadingButton
                  loading={false}
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={styles.buttonBlock}
                  fullWidth
                >
                  {props.editedUser ? 'izmenite' : 'dodaj'} korisnika
                </LoadingButton>
              </Grid>
              <Grid item className={styles.loginItem} style={btnstyle}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={styles.buttonBlock}
                  fullWidth
                  onClick={props.handleCloseModule}
                >
                  Odustani
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </>
  );
};

export default AddUserComponent;
