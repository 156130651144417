import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { RegisterUserType } from '.';
import styles from '../AddUser/styles.module.css';

const UserForm: React.FC = () => {
  const formik = useFormikContext<RegisterUserType>();
  return (
    <Grid container>
      <Grid item className={styles.loginItem}>
        <TextField
          error={Boolean(formik.errors.email)}
          name="email"
          label="Korisnički e-mail"
          placeholder="Unesite korisnički e-mail"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          fullWidth
        />
        {formik.errors.email ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.email}</i>
          </Typography>
        ) : (
          formik.errors.email && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.email}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          error={formik.errors.password != null}
          name="password"
          label="Lozinka"
          placeholder="Unesite lozinku"
          type="password"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />{' '}
        {formik.errors.password ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.password}</i>
          </Typography>
        ) : (
          formik.errors.password && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.password}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          error={formik.errors.confirmPassword != null}
          name="confirmPassword"
          label="Potvrda lozinke"
          placeholder="Unesite lonovo lozinku"
          type="password"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
        />{' '}
        {formik.errors.confirmPassword ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.confirmPassword}</i>
          </Typography>
        ) : (
          formik.errors.confirmPassword && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.confirmPassword}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="firstName"
          label="Ime"
          placeholder="Unesite ime"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.errors.firstName != null}
        />
        {formik.errors.firstName ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.firstName}</i>
          </Typography>
        ) : (
          formik.errors.firstName && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.firstName}</i>
            </Typography>
          )
        )}
      </Grid>
      <Grid item className={styles.loginItem}>
        <TextField
          name="lastName"
          label="Prezime"
          placeholder="Unesite prezime"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.errors.lastName != null}
          fullWidth
        />
        {formik.errors.lastName ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.lastName}</i>
          </Typography>
        ) : (
          formik.errors.lastName && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.lastName}</i>
            </Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default UserForm;
