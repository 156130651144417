import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { RegisterLicenseType } from '.';
import styles from '../AddUser/styles.module.css';
import { useAppDispatch } from 'src/redux';
import { getAllLicenses, getAllSingleLicenses } from 'src/redux/slices/companySlice';

const LicenseForm: React.FC<{ single?: boolean }> = ({ single }) => {

  const [licenses, setLicenses] = useState([]);
  const [selected, setSelected] = useState(null);
  const dispatch = useAppDispatch();

  const handleSelect = (id) => {
    formik.setFieldValue('licenseId', id);
    setSelected(id);
  };

  useEffect(() => {
    if(single) {
      dispatch(getAllSingleLicenses()).then((data) => {
        setLicenses(data.payload);
      });
    }
    if(!single) {
      dispatch(getAllLicenses()).then((data) => {
        setLicenses(data.payload);
      });
    }
  }, [single]);

  const formik = useFormikContext<RegisterLicenseType>();
  return (
    <Grid container spacing={2} className={styles.loginItem}>
      {licenses?.map((license) => {
        return (
          <Grid item xs={12} md={4} key={license.id}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: 275,
                border: '2px solid',
                borderColor: selected === license.id ?
                  'success.main' : 'transparent'
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {license.val}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {license.note}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelect(license.id)}
                  fullWidth
                >
                  Izaberi
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
      <Grid item className={styles.loginItem}>
        {formik.errors.licenseId ? (
          <Typography color="error" className={styles.textForm}>
            <i>{formik.errors.licenseId}</i>
          </Typography>
        ) : (
          formik.errors.licenseId && (
            <Typography color="error" className={styles.textForm}>
              <i>{formik.errors.licenseId}</i>
            </Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default LicenseForm;
