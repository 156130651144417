import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import CompanyForm from './CompanyForm';
import UserForm from './UserForm';
import LicenseForm from './LicenseForm';
import React, { useState } from 'react';
import { useAppDispatch } from 'src/redux';
import Logo from '../Logo';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { companySelector, signUpCompany } from 'src/redux/slices/companySlice';

const paperStyle = {
  padding: 24,
  width: 800,
  margin: '24px auto'
};

export const requiredErrorMsg = 'Ovo polje je obavezno';

export interface RegisterCompanyType {
  pib: string;
  companyName: string;
  organizationType: string;
  industryId: number;
  companyAddress: string;
  companyHeadquarters: string;
  companyPhone: string;
}
export interface RegisterUserType {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}
export interface RegisterLicenseType {
  licenseId: number;
}

const initialValueCompany: RegisterCompanyType = {
  pib: '',
  companyName: '',
  organizationType: '',
  industryId: null,
  companyAddress: '',
  companyHeadquarters: '',
  companyPhone: ''
};

const initialValueUser: RegisterUserType = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: ''
};

const initialValueLicense: RegisterLicenseType = {
  licenseId: null
};

const validationCompany = yup.object().shape({
  pib: yup
    .number()
    .typeError('*PIB može da sadrži samo brojeve')
    .test(
      'len',
      '*PIB mora da ima 9 znakova',
      (val) => val && val.toString().length === 9
    )
    .required(requiredErrorMsg),
  companyName: yup
    .string()
    .max(50, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg),
  organizationType: yup
    .string()
    .max(50, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg),
  industryId: yup.string().required(requiredErrorMsg),
  companyAddress: yup
    .string()
    .max(100, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg),
  companyHeadquarters: yup
    .string()
    .max(100, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg),
  companyPhone: yup
    .string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/i,
      '*Telefon kompanije nije validan'
    )
    .max(15, '*Telefon kompanije nije validan')
    .required(requiredErrorMsg)
});
const validationUser = yup.object().shape({
  email: yup.string().email('Email nije validan').required(requiredErrorMsg),
  password: yup
    .string()
    .min(8, '*Lozinka mora da sadrži minimum 8 znakova')
    .required(requiredErrorMsg),
  confirmPassword: yup
    .string()
    .required(requiredErrorMsg)
    .oneOf([yup.ref('password'), null], 'Sifra mora biti ista'),
  firstName: yup
    .string()
    .max(50, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg),
  lastName: yup
    .string()
    .max(50, '*Polje sadrži više od maksimalnog broja karaktera')
    .required(requiredErrorMsg)
});
const validationLicense = yup.object().shape({
  licenseId: yup.string().required(requiredErrorMsg)
});

let message;

const RegisterCompanyComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pending = useSelector(companySelector.pending);

  const steps = [
    'Izaberite licencu',
    'Podaci o kompaniji',
    'Podaci o korisniku'
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <LicenseForm />;
      case 1:
        return <CompanyForm />;
      case 2:
        return <UserForm />;
      default:
        return <>Not Found</>;
    }
  };

  const getValidations = (step: number) => {
    switch (step) {
      case 0:
        return validationLicense;
      case 1:
        return validationCompany;
      case 2:
        return validationUser;
      default:
        return {};
    }
  };

  const getInitialValues = (step: number) => {
    switch (step) {
      case 0:
        return initialValueLicense;
      case 1:
        return initialValueCompany;
      case 2:
        return initialValueUser;
      default:
        return {};
    }
  };

  return (
    <div className={styles.loginBackgorund}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={styles.loginForm}
      >
        <Paper elevation={10} style={paperStyle}>
          <Grid item className={styles.logoPosition + ' ' + styles.loginItem}>
            <Logo></Logo>
          </Grid>
          <Grid item className={styles.loginItem}>
            <Typography variant="h2" gutterBottom>
              Registracija
            </Typography>
            <Typography variant="h6">Molimo vas da popunite podatke</Typography>
          </Grid>
          <Formik
            onSubmit={async (values: any) => {
              let response;
              if (activeStep === 0) {
                setActiveStep(1);
                return Promise.resolve(true);
              } else if (activeStep === 1) {
                setActiveStep(2);
                return Promise.resolve(true);
              } else {
                delete values.confirmPassword;
                response = await dispatch(signUpCompany(values));
                if (response.meta.requestStatus === 'fulfilled') {
                  navigate('/success');
                }
                if (response.meta.requestStatus === 'rejected') {
                  if (response.error.name === 'Error 400') {
                    message = response.error.message;
                  } else {
                    message = 'Došlo je do greške na serveru';
                  }
                }
              }
            }}
            initialValues={getInitialValues(activeStep)}
            validationSchema={getValidations(activeStep)}
          >
            {({ isSubmitting }) => {
              return (
                <>
                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Form>
                    {renderStepContent(activeStep)}
                    {message && (
                      <Box className={styles.errorMessage}>
                        <Typography color="error" className={styles.textForm}>
                          <i>{message}</i>
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      {activeStep === 0 && (
                        <Button
                          color="primary"
                          variant="outlined"
                          type="button"
                          onClick={() => navigate('/register')}
                          sx={{ mr: 1 }}
                        >
                          Nazad na izbor tipa klijenta
                        </Button>
                      )}
                      {activeStep !== 0 && (
                        <Button
                          color="primary"
                          variant="outlined"
                          type="button"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Nazad
                        </Button>
                      )}
                      <Box sx={{ flex: '1 1 auto' }} />
                      <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                        loading={pending}
                      >
                        {activeStep === steps.length - 1
                          ? 'Registruj kompaniju'
                          : 'Dalje'}
                      </LoadingButton>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
    </div>
  );
};

export default RegisterCompanyComponent;
